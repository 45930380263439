import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import positive from "../../assets/audio/Positive1.mp3";
import Alert from "@mui/material/Alert";

function App() {
  const navigate = useNavigate();
  const { Topics, setTop } = useCTopic();
  const [data, setData] = useState(null);
  const [Mytopics, setMytopics] = useState([]);

  function play() {
    new Audio(positive).play();
  }

  // Function to set the topic when a button is clicked
  const handleTopicSelection = (selectedTopic) => {
    setTop(selectedTopic);
    play();
    navigate("/Q1");
  };

  // Memoize Mytopics to cache the result
  const cachedTopics = useMemo(() => Topics, [Topics]);

  return (
    <div className="container lg:mx-24 mx-auto p-0 flex lg:mt-10 flex-col lg:flex-row lg:gap-10">

      <div className="md:w-8/12 pr-4 flex flex-col ">
        <h1 className="text-4xl font-bold ml-8 mt-4 leading-tighter tracking-tighter mb-4">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
            Interview Simulator
          </span>
        </h1>
        <div className="max-w-3xl mx-auto">
          <p className="text-xl font-light ml-8 text-gray-600 dark:text-neutral-400 mb-4">
            This simulator is designed to help you build confidence, sharpen
            your interview skills and ace your next job interview
          </p>
          <p className="text-xl font-medium ml-8 text-gray-600 dark:text-neutral-400 mb-8">
            Please select the topic that you would like to practice
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg md:w-8/12 md:mt-1/2 md:ml-4 dark:bg-neutral-700 dark:border-neutral-600">
        <div className="flex-1 flex flex-col gap-4 p-4">
          {/* First Column */}
          {Topics.slice(0, Math.ceil(Topics.length / 2)).map(
            (myTopic, index) => (
              <button
                key={index}
                className={`text-centre p-4 rounded-md ${
                  myTopic !== "Product"
                    ? "cursor-not-allowed text-neutral-400 hover:bg-neutral-50 dark:text-neutral-400 dark:hover:bg-neutral-600"
                    : "hover:bg-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 dark:hover:bg-neutral-800 dark:text-white "
                }`}
                onClick={() => handleTopicSelection(myTopic)}
                disabled={myTopic !== "Product"}
              >
                {myTopic}
              </button>
            )
          )}
        </div>

        {/* Second Column */}
        <div className="flex-1 flex flex-col gap-4 p-4">
          {Topics.slice(Math.ceil(Topics.length / 2)).map((myTopic, index) => (
            <button
              key={index}
              className={`text-centre p-4 rounded-md ${
                myTopic !== "Product"
                ? "cursor-not-allowed text-neutral-400 hover:bg-neutral-50 dark:text-neutral-400 dark:hover:bg-neutral-600"
                : "hover:bg-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 dark:hover:bg-neutral-800 dark:text-white "
              }`}
              onClick={() => handleTopicSelection(myTopic)}
              disabled={myTopic !== "Product"}
            >
              {myTopic}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
