// src/components/Home.jsx
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import chatBackgroundImage from "../../assets/images/dashboard.jpg";
import axios from "axios";
import { LineChart } from "@mui/x-charts/LineChart";
import { useLocation } from "react-router-dom";
import { useCUser } from "../../contexts/Cusercontext";
import Carousel from "../../components/candidate/CardCarosel";

export default function Dashboard() {
  const { setUser } = useCUser();
  const { setFirst } = useCUser();
  const { setLast } = useCUser();
  const { setEmail } = useCUser();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const userId = query.get("user_id");
  const email = query.get("email");
  const firstName = query.get("first_name"); // Get first_name from query parameters

  useEffect(() => {
    if (firstName) {
      setFirst(firstName); // Set first name in global context
      console.log("Firstname set in global context as:", firstName);
    }
  }, [firstName, setFirst]);

  const items = [
    {
      title: 'Product Manager',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Google_logo.svg/2560px-Google_logo.svg.png', // Google logo
      company: 'Facebook',
      role: 'Product Manager',
      cost: '200 TKNS',
    },
    {
      title: 'Data Scientist',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Google_logo.svg/2560px-Google_logo.svg.png', // Google logo
      company: 'Amazon',
      role: 'Data Scientist',
      cost: '200 TKNS',
    },
    {
      title: 'Frontend Developer',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Google_logo.svg/2560px-Google_logo.svg.png', // Google logo
      company: 'AirBNB',
      role: 'Frontend Developer',
      cost: '200 TKNS',
    },
    
   
   
  ];



 


  // Funtion to handle the fetching of subjects
  return (
    <div className="container p-4 " style={{marginLeft: '3.5vw',}}>

      {/* Unified template with responsive utilities */}
      <div className={`w-[89vw] md:w-[92vw] lg:h-[84vh] lg:w-[90vw] xl:w-[90vw] xl:h-[70vh] rounded-md bg-purple-200 sm:bg-white md:bg-white lg:bg-white lg:dark:bg-neutral-800 xl:bg-pink-100 xl:dark:bg-neutral-800`}>
        <h1 className="text-2xl font-bold leading-tighter tracking-tighter">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-neutral-700 via-gray-900 to-black dark:text-white">
            Dashboard
          </span>
        </h1>
        
        <div className="mt-4 bg-neutral-400 dark:bg-white w-[89vw] h-0.5 mb-2 md:mb-4 rounded-md"></div>

        <div>
          <h2 className="text-lg dark:text-white font-bold mt-4">
            Recommended Simulations
          </h2>

          <div className="mt-4 mb-12">
            <Carousel className="w-[80vw]" items={items} visibleCards={4} interval={2000} />
          </div>
     
        </div>

        <div className="flex flex-row gap-2">
           {/* Refer a Friend Section */}
           <div className="flex flex-col ml-0 mr-2">
          <div className="bg-stone-200 w-[30vw] h-56 rounded-md ml-1 flex flex-col p-2">
            <h1 className="text-xl font-semibold ml-2 mt-2">Refer a friend</h1>
            <h2 className="text-sm font-light ml-2">
              Refer a friend using your code and gain 200 TKNS for free
            </h2>
            <div className="bg-stone-400 rounded-md mt-2 flex flex-row ml-2 mr-2">
              <h1 className=" ml-2 p-2 w-36 rounded-md font-semibold text-sm mt-0.5">
                Guest2371521
              </h1>
              <button className="ml-2 bg-black text-stone-200 w-12 rounded-md h-10 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>
            <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
            <h1 className="text-sm font-light ml-2 mt-2">
              *You can only gain tokens for a maximum of 5 referred users
            </h1>
          </div>
          </div>
           {/* Refer a Friend Section */}
           <div className="flex flex-col ml-0 mr-2">
          <div className="bg-stone-200 w-[30vw] h-56 rounded-md ml-1 flex flex-col p-2">
            <h1 className="text-xl font-semibold ml-2 mt-2">Refer a friend</h1>
            <h2 className="text-sm font-light ml-2">
              Refer a friend using your code and gain 200 TKNS for free
            </h2>
            <div className="bg-stone-400 rounded-md mt-2 flex flex-row ml-2 mr-2">
              <h1 className=" ml-2 p-2 w-36 rounded-md font-semibold text-sm mt-0.5">
                Guest2371521
              </h1>
              <button className="ml-2 bg-black text-stone-200 w-12 rounded-md h-10 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>
            <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
            <h1 className="text-sm font-light ml-2 mt-2">
              *You can only gain tokens for a maximum of 5 referred users
            </h1>
          </div>
          </div>
          
          
          {/* Refer a Friend Section */}
          <div className="flex flex-col ml-0 mr-2">
          <div className="bg-stone-200 w-56 h-56 rounded-md ml-1 flex flex-col p-2">
            <h1 className="text-xl font-semibold ml-2 mt-2">Refer a friend</h1>
            <h2 className="text-sm font-light ml-2">
              Refer a friend using your code and gain 200 TKNS for free
            </h2>
            <div className="bg-stone-400 rounded-md mt-2 flex flex-row ml-2 mr-2">
              <h1 className=" ml-2 p-2 w-36 rounded-md font-semibold text-sm mt-0.5">
                Guest2371521
              </h1>
              <button className="ml-2 bg-black text-stone-200 w-12 rounded-md h-10 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                  />
                </svg>
              </button>
            </div>
            <hr className="h-0.5 mt-1 mr-2 rounded-md bg-black ml-2 mt-4"></hr>
            <h1 className="text-sm font-light ml-2 mt-2">
              *You can only gain tokens for a maximum of 5 referred users
            </h1>
          </div>
          </div>
        </div>

       
        
        
       
        
       
      </div>
    </div>
  );
}
