// components/Layout.js
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Newsletter from "./Newsletter";
import { useCUser } from "../contexts/Cusercontext";

const Layout = ({ children }) => {
  const { isDarkMode, toggleDarkMode } = useCUser();
  return (
    <div style={{ height: "100%" }} className="bg-white dark:bg-neutral-800 ">
      <Header />
      {children}
    </div>
  );
};

export default Layout;
