// src/components/Home.jsx
import React, { useState } from "react";
import { useCUser } from "../contexts/Cusercontext";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import chatBackgroundImage from "../assets/images/welcome.png";


export default function Home() {
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [role, setRole] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [successMsgOpen, setsuccessMsgOpen] = useState(false);
  const { toggleDarkMode, isDarkMode } = useCUser();

  // Function to close the notification for Success messages
  const handleSuccessMsgClose = async () => {
    setsuccessMsgOpen(false);
    setsuccessMsg("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const apply = () => {
    console.log(email);
    console.log(url);
    console.log(role);
    fetch("http://localhost:5000/api/applyadmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Email: email, URL: url, Role: role }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setsuccessMsg("Successfully Applied");
        setsuccessMsgOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container p-4 " style={{marginLeft: '3.5vw'}}>
    {/* Space on top to prevent collision with header */}
    <div className="mt-2 w-full rounded-md h-4 md:h-6 xl:h-6"></div>

    {/* Unified template with responsive utilities */}
    <div style={{width:'90vw'}} className={`p-4 rounded-md h-[100vh] bg-white dark:bg-neutral-800 sm:dark:bg-neutral-800 md:bg-white md:dark:bg-neutral-800 lg:bg-white lg:dark:bg-neutral-800 xl:bg-white`}>
    
      
    


      <div style={{ zIndex: '1',width:'70vw' }} className="flex md:ml-[26vw] lg:ml-[16vw] flex-col md:flex-row gap-8 ">
        {/* Initial Introductory Banner */}
        <div style={{ zIndex: '1' }} className="text-left w-full lg:w-[25vw] mb-0 md:mb-16 mt-4 xl:mt-auto md:pb-16">
      
            <div className="w-72 mt-8 md:mt-8 text-left">
              <span className="bg-clip-text text-4xl font-medium text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                Join Our New Admin Program
              </span>
              <p className="w-72 mt-4 text-justify text-lg text-gray-600 dark:text-neutral-200 mb-4">
                Join Our Team: Shape the Future of Interview Preparation with
                AVA and get exciting perks
              </p>
              <form>
                <div className="flex flex-wrap mb-4">
                  <div className="w-56">
                    <label
                      className="block text-sm sr-only"
                      htmlFor="newsletter"
                    >
                      Email Connected to Linkedin
                    </label>
                    <div className="relative flex flex-col gap-2 items-center ml-16">
                      <input
                        onChange={handleEmailChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-neutral-700 px-4 py-3 text-md focus:outline-none"
                        placeholder="Email Connected to Linkedin"
                        required
                      />
                      <input
                        onChange={handleUrlChange}
                        id="newsletter"
                        type="email"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-neutral-700 px-4 py-3 text-md focus:outline-none"
                        placeholder="Linkedin Profile URL"
                        required
                      />
                      <select
                        onChange={handleRoleChange}
                        id="newsletter"
                        className="form-input w-72 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-neutral-700 px-4 py-3 text-md focus:outline-none"
                        required
                      >
                        <option value="" disabled hidden>
                          Select an Option
                        </option>
                        <option value="superadmin">Super Admin</option>
                        <option value="admin">Admin</option>
                        <option value="readonlyadmin">Read Only Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <button
                onClick={apply}
                className="flex flex-row text-md gap-2 px-4 py-3 w-72 h-12 mt-6 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black"
              >
                Apply Now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
         
        </div>

        <img style={{zIndex: '1'}} src={chatBackgroundImage} alt="Chat Background" className="object-cover hidden lg:flex w-[60vw] xl:mb-[30vh] md:w-[30vw] md:h-[25vh] lg:mt-[13vh] xl:mt-[10vh] lg:w-[30vw] xl:h-[40vh] lg:h-[60vh] rounded-md"/>

        
      </div>
     
    </div>
   
  </div>
  );
}
