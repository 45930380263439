import React from 'react';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-4 flex justify-center">
      <div className="h-full bg-gray-100 dark:bg-neutral-700 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center">
        {/* Icon */}
        <div className="p-6 text-center flex flex-col items-center justify-center">
          <div className="text-gray-900 dark:text-gray-100 mb-4">
            {icon}
          </div>
          {/* Title */}
          <h2 className="text-2xl font-light text-gray-900 dark:text-gray-100 mb-2 text-center">
            {title}
          </h2>
          {/* Description */}
          <p className="text-lg text-gray-600 dark:text-gray-400 text-center">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
