import React, { useState, useEffect } from 'react';

const Carousel = ({ items, interval = 3000, visibleCards = 4 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalItems = items.length;

  // Create a seamless loop by duplicating the first item
  const extendedItems = items.concat(items[0]);

  useEffect(() => {
    const autoScroll = setInterval(() => {
      handleNext();
    }, interval);

    return () => clearInterval(autoScroll); // Cleanup interval on unmount
  }, [interval]);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % extendedItems.length);
  };

  // Only handle the visual display without actually going backward
  const handlePrev = () => {
    // Disable backward navigation to keep it flowing left to right
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => (prevIndex - 1 + extendedItems.length) % extendedItems.length);
    }
  };

  return (
    <div className="flex flex-row gap-4 w-[89vw] overflow-hidden items-center">
         {/* Navigation Buttons */}
        <button
            onClick={handlePrev}
            style={{zIndex: 1}}
            className="dark:bg-neutral-300 rounded-md p-2"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
            </svg>

        </button>

      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${(activeIndex * (100 / visibleCards))}%)`,
          width: `${(extendedItems.length) * (100 / visibleCards)}%`,
        }}
      >
        {extendedItems.map((item, index) => (
          <div
            key={index}
            className="h-24 ml-4 pt-4 rounded-md bg-gray-50 dark:bg-neutral-700 dark:hover:bg-neutral-600 hover:bg-gray-100 flex-shrink-0 flex flex-col items-left justify-center p-5"
            style={{ width: `${(100 / visibleCards)}%` }}
          >
           
            
            <p className="dark:text-white text-gray-700">{item.company}</p>
            <p className="dark:text-white text-gray-500">{item.role}</p>
            <p className="dark:text-white text-green-600 font-semibold">{item.cost}</p>
          </div>
        ))}
      </div>

     
      <button
        onClick={handleNext}
        style={{zIndex: 1}}
        className="dark:bg-neutral-300 rounded-md p-2"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
        </svg>

      </button>
    </div>
  );
};

export default Carousel;
