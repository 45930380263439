import React, { useState } from "react";
import axios from "axios";
import Sidenav from "./admin/Topnav";
import { Link } from "react-router-dom";
import { useCTopic } from "../contexts/Ctopiccontext";

const Test = ({ children }) => {
  const { Topics, setTop } = useCTopic();
  const [selectedTopic, setSelectedTopic] = useState("");

  const handleClick = (value) => {
    setTop(value);
    setSelectedTopic(value);
  };

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleHamburgerToggle = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  return (
    <div className="dark:bg-neutral-800  flex flex-col md:flex-row gap-2 p-2 lg:pl-2 lg:pr-4 lg:pb-6 lg:pt-2 max-w-screen-xl h-full">
      <div
        className="hidden w-[15vw] h-[85vh] ml-[3vw] lg:block text-left dark:bg-neutral-800  rounded-md"

      >
        <div className="mt-0">
          {Topics.map((topic, index) => (
            <button
              key={index}
              className={`w-48 text-sm font-semibold ml-2 h-10 mr-4 mt-1 p-1 ${
                selectedTopic === topic
                  ? "bg-slate-400 dark:bg-neutral-300 dark:text-black rounded-md text-white"
                  : "bg-slate-200 dark:bg-neutral-500 rounded-md"
              } hover:bg-slate-400 hover:text-white`}
              onClick={() => handleClick(topic)}
            >
              {topic}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-gray-400 h-[75vh] dark:bg-white w-0.5 rounded-md ml-4"></div>
      <div
        style={{ width: "75%", height: "99%" }}
        className="flex flex-col lg:mt-2 dark:bg-neutral-800 ml-4 h-screen"
      >
        {children}
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 z-40 bg-gray-800 bg-opacity-75 transition-opacity duration-300 ${
          isHamburgerMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
        } lg:hidden`}
        onClick={handleHamburgerToggle}
      >
        <div className="fixed top-0 right-0 h-full w-64 bg-white dark:bg-gray-800 p-4 shadow-lg">
          <button
            onClick={handleHamburgerToggle}
            className="p-2 text-gray-600 dark:text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="flex flex-col mt-4 h-full dark:bg-gray-800">
            <li className="flex-shrink-0 mb-4 text-light text-xl dark:text-white">
              <span className="ml-2">Simulator Manager</span>
            </li>
            <li className="py-2">
              {Topics.map((topic, index) => (
                <button
                  key={index}
                  className={`rounded-md block w-full text-left mt-2 p-2 ${
                    selectedTopic === topic
                      ? "bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-100"
                      : "text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
                  } `}
                  onClick={() => handleClick(topic)}
                >
                  {topic}
                </button>
              ))}
            </li>
          </ul>
        </div>
      </div>

      {/* Fixed Toggle Button */}
      <button
        className="lg:hidden fixed bottom-36 right-4 p-3 bg-slate-500 text-white rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300"
        onClick={handleHamburgerToggle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 9h16.5m-16.5 6.75h16.5"
          />
        </svg>
      </button>
    </div>
  );
};

export default Test;
