import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/coming-soon.png";

function Setting() {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("Account Info");

  const [selected, setSelected] = useState("Account Info");

  console.log(selected);

  // Function to set the topic when a button is clicked
  const handleSectionSelection = (selectedSection) => {
    setSelected(selectedSection);
    console.log(selected);
  };

  return (
    <div
    className="container p-4 "
    style={{
      marginLeft: '3.5vw',
     
    }}
  >
  
        {/* Unified template with responsive utilities */}
        <div className={`w-[89vw] mt-[0vh] md:w-[92vw] lg:h-[84vh] lg:w-[90vw] xl:w-[90vw] xl:h-[70vh] rounded-md bg-white dark:bg-neutral-800 sm:bg-white md:bg-white lg:bg-white lg:dark:bg-neutral-800 xl:bg-pink-100 xl:dark:bg-neutral-800`}>
          <h1 className="text-3xl font-bold leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-neutral-700 via-gray-900 to-black dark:text-white">
              Settings Page
            </span>
          </h1>
          <p className="dark:text-neutral-400 mt-2 md:mt-0">
            This template adapts to different screen sizes with responsive
            styling. Below are stacked divs that change from flex row to felx col according to screen size.
          </p>


          <div className="lg:block mt-6 mr-3 rounded-md">
            <div className="flex flex-row gap-4 mb-4">
              {/* Tabs */}
              {["Account Info", "Billing", "Feedback"].map((tab) => (
                <button
                  key={tab}
                  className={`w-24 text-sm p-2 rounded-md ${
                    activeTab === tab
                      ? "bg-black text-stone-200"
                      : "bg-stone-200 text-black hover:bg-black hover:text-stone-200"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            <div className="bg-neutral-400 dark:bg-white w-[89vw] h-0.5 mb-2 md:mb-4 rounded-md"></div>

            {/* Conditional divs based on active tab */}
            <div className="w-[41vh] md:w-[89vw] h-[68vh] dark:text-white lg:h-[60vh] bg-white dark:bg-neutral-800  rounded-md">
              {activeTab === "Account Info" && (
                <div>
                  {/* Content for Account Info */}
                  <h1 className="text-xl font-bold leading-tighter tracking-tighter mb-4">
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                        Account Information.
                      </span>
                  </h1>
                </div>
              )}
              {activeTab === "Billing" && (
                <div className="flex flex-col gap-4">
                  <h1 className="text-xl font-bold leading-tighter tracking-tighter mb-4">
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                        Billing Information.
                      </span>
                  </h1>
                </div>
              )}

              {activeTab === "Feedback" && (
                <div className="flex flex-row gap-24">
                  {/* Content for Feedback */}
                  <div className="w-60 text-justify">
                    <h1 className="text-3xl font-bold leading-tighter tracking-tighter mb-4">
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                        Your Feedback is important for us.
                      </span>
                    </h1>
                    <div className="max-w-3xl">
                      <p className="text-xl font-light text-gray-600 dark:text-gray-400 mb-4">
                        Fill up this short feedback form to notify us of any
                        potential bugs, issues or suggestions you may have.
                      </p>
                      <p className="text-lg font-medium text-gray-600 dark:text-gray-400 mb-8">
                        Please select the topic that you would like to practice
                      </p>
                    </div>
                  </div>
                  <form>
                    <div className="flex flex-wrap mb-4">
                      <div className="w-full md:w-96 mt-4">
                        {/* Query Type Select */}
                        <select
                          id="queryType"
                          className="form-input w-full mb-2 text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                          required
                        >
                          <option value="" disabled hidden>
                            Query Type
                          </option>
                          <option value="suggestion">Suggestion</option>
                          <option value="bug">Reporting a Bug</option>
                          <option value="issue">Issue</option>
                        </select>

                        {/* Textarea for Description */}
                        <textarea
                          id="description"
                          className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none mb-2"
                          placeholder="Describe your issue"
                          style={{ width: "100%", height: "100px" }}
                          required
                        />

                        {/* File Attachment */}
                        <div className="mb-2">
                          <label
                            htmlFor="fileAttachment"
                            className="block text-sm font-medium text-gray-800 dark:text-gray-200"
                          >
                            Attach File (if necessary)
                          </label>
                          <input
                            id="fileAttachment"
                            type="file"
                            className="form-input w-full text-gray-800 dark:text-gray-200 bg-gray-200 dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>

         
        </div>
      </div>
  );
}

export default Setting;
