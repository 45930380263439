// components/Layout.js
import Sidenav from "./admin/Topnav";
import React, { useState, useRef, useEffect } from "react";
import { useCTopic } from "../contexts/Ctopiccontext";

const MainLayout = ({ children }) => {
  const { Topics, setTopics } = useCTopic();

  // Function to fetch topics from Flask backend
  const setStatus = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/retrievetopics`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch topics");
      }

      const data = await response.json();
      const { topics } = data;
      setTopics(topics); // Set state with fetched topics array
    } catch (error) {
      console.error("Error fetching topics:", error);
      // Handle error, show user feedback, etc.
    }
  };

  const fetchTopics = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/retrievetopics`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch topics");
      }

      const data = await response.json();
      const { topics } = data;
      setTopics(topics); // Set state with fetched topics array
    } catch (error) {
      console.error("Error fetching topics:", error);
      // Handle error, show user feedback, etc.
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div
      style={{
        height: "595px",
      }}
      className="flex flex-col dark:bg-neutral-800 "
    >
      <Sidenav />
      <div className="mt-8 lg:mt-16 dark:bg-neutral-800 "></div>

      {children}
    </div>
  );
};

export default MainLayout;
