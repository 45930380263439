import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import negative from "../../assets/audio/Negative.mp3";
import positive from "../../assets/audio/Positive1.mp3";

function Q2() {
  const navigate = useNavigate();
  const {
    company,
    setCompanyContext,
    question,
    setQuestionContext,
    topic,
    subtopic,
    subtype,
    setSubtypeContext,
  } = useCTopic();
  const [loadingMessage, setLoadingMessage] = useState("");

  function play1() {
    new Audio(negative).play();
  }

  function play2() {
    new Audio(positive).play();
  }

  const getQuestions = async () => {
    setLoadingMessage("Generating Questions based on Company...");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/generate_questions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            topic_name: topic,
            subtopic_name: subtopic,
            desired_company: company,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }

      const data = await response.json();
      if (data.error) {
        setQuestionContext("");
        setSubtypeContext("");
        setLoadingMessage("");
      } else {
        const randomQuestion =
          data.questions && data.questions.length > 0
            ? data.questions[Math.floor(Math.random() * data.questions.length)]
            : "";
        setQuestionContext(randomQuestion);
        setSubtypeContext(data.subtype_name);
        setLoadingMessage("Successfully picked a question!");
        play2();
        navigate("/chat");
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
      setQuestionContext([]);
      setSubtypeContext("");
      setLoadingMessage("Failed to generate questions.");
    }

    setTimeout(() => {
      setLoadingMessage("");
    }, 2000);
  };

  const handleBack = () => {
    play1();
    setCompanyContext("");
  };

  const showTemporaryMessage = (message) => {
    setLoadingMessage(message);
    setTimeout(() => {
      setLoadingMessage("");
    }, 2000);
  };

  return (
    <div className="container lg:mx-24 mx-auto p-0 flex lg:mt-2 flex-col lg:flex-row lg:gap-10">
      {/* Layout for small and medium screens */}
      <div className="block lg:hidden ml-4 mx-3 p-6 rounded-md dark:bg-neutral-800 dark:text-white">
        <div className="flex flex-col gap-2">
          <div style={{ width: "100%" }} className="flex flex-row mb-4">
            <Link to="/Q1">
              <button
                className="flex flex-row gap-2 p-2 w-36 h-10 bg-stone-300 dark:bg-stone-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600"
                onClick={() => handleBack()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
                Back
              </button>
            </Link>
            {company && (
              <button
                style={{ width: "45%" }}
                className="flex flex-row gap-2 p-2 h-10 bg-stone-300 dark:bg-stone-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600 ml-auto"
                onClick={() => {
                  showTemporaryMessage("Randomising a Subtype");
                  setTimeout(() => {
                    getQuestions();
                  }, 2000);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
                Next
              </button>
            )}
          </div>

          <div className="text-3xl font-light">
            Subtopic Selected: {subtopic}
          </div>
          <h1 className="text-5xl font-bold mt-4 leading-tighter tracking-tighter">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              Let's go into more detail about your selected subtopic
            </span>
          </h1>
          <h2 className="text-2xl font-thin mt-4">
            Is there a particular company that you are applying for? This
            information might help me customize your simulation session to be
            tailored to your needs.
          </h2>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <input
            type="text"
            placeholder="Enter company name"
            value={company}
            onChange={(e) => setCompanyContext(e.target.value)}
            className="p-2 h-16 w-full text-xl bg-slate-200 rounded dark:bg-neutral-700 dark:text-white focus:outline-none"
          />
          {loadingMessage && (
            <div className="mt-4 text-center">{loadingMessage}</div>
          )}
        </div>
      </div>

      {/* Layout for large and extra large screens */}
      <div className="hidden lg:flex flex-row gap-10 px-12 pb-4 pt-10 mx-auto max-w-screen-xl h-auto dark:bg-neutral-800 dark:text-white">
    
      
        <div className="w-full lg:w-1/2">
          <div className="text-2xl font-light mb-2">
            Subtopic Selected: {subtopic}
          </div>
          <h1 className="text-4xl font-bold mt-2 leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              Let's go into more detail about your selected subtopic
            </span>
          </h1>
          <h2 className="text-xl font-thin mb-4">
            Is there a particular company that you are applying for? This
            information might help me customize your simulation session to be
            tailored to your needs.
          </h2>
          <div className="flex flex-row gap-8">
            <Link to="/Q1">
              <button
                className="flex flex-row gap-2 p-2 w-36 h-10 bg-stone-300 dark:bg-neutral-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-neutral-600"
                onClick={() => handleBack()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
                Back
              </button>
            </Link>
            {company && (
              <button
                className="flex flex-row gap-2 p-2 w-36 h-10 bg-stone-300 dark:bg-neutral-700 justify-center items-center text-black dark:text-white rounded-md hover:bg-black hover:text-stone-200 dark:hover:bg-neutral-600"
                onClick={() => {
                  showTemporaryMessage("Randomising a Subtype");
                  setTimeout(() => {
                    getQuestions();
                  }, 2000);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
                Next
              </button>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col rounded-md shadow-lg dark:bg-neutral-700 dark:border-neutral-600 p-4">
          <input
            type="text"
            placeholder="Enter company name"
            value={company}
            onChange={(e) => setCompanyContext(e.target.value)}
            className="p-2 w-full text-sm bg-slate-200 rounded dark:bg-neutral-700 dark:text-white focus:outline-none"
          />
          {loadingMessage && (
            <div className="mt-4 text-center">{loadingMessage}</div>
          )}
        </div>
       
      </div>
    </div>
  );
}

export default Q2;
