import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCUser } from "../contexts/Cusercontext";
import chatBackgroundImage from "../assets/images/background.jpg"
import Footer from "../components/Footer"
import CustomAccordion from '../components/customAccordian';
import FeatureCard from '../components/customFeatureCard';

export default function Home() {
  const [data, setData] = useState(null);
  const { toggleDarkMode, isDarkMode } = useCUser();

  const features = [
    {
      icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
    ,
      title: 'AI-Powered Interviews',
      description: 'Experience AI-driven interview simulations tailored to your specific needs.',
    },
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
    ,
      title: 'Security & Privacy',
      description: 'Your data is protected with top-notch security and encryption standards.',
    },
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
    ,
      title: 'Customizable Settings',
      description: 'Easily configure your interview preferences for an enhanced experience.',
    },
  ];

  



  return (
    <div className="container p-4 " style={{marginLeft: '3.5vw'}}>
      {/* Space on top to prevent collision with header */}
      <div className="mt-24 w-full rounded-md h-4 md:h-6 xl:h-6"></div>

      {/* Unified template with responsive utilities */}
      <div style={{width:'90vw'}} className={`p-4 rounded-md bg-white dark:bg-neutral-800 sm:dark:bg-neutral-800 md:bg-white md:dark:bg-neutral-800 lg:bg-white lg:dark:bg-neutral-800 xl:bg-teal-200`}>
      
        
        <div style={{ zIndex: '0' }} className="w-full">
          <svg
            viewBox="0 0 100 20"
            className="absolute top-1/2 left-0 transform -translate-y-1/2 w-full h-full"
          >
            <path
              d="M0 10 Q25 0, 50 10 T100 10"
              fill="none"
              stroke={isDarkMode ? '#737373' : '#d1d5db'} // Tailwind's blue-600 color
              strokeWidth="2"
              strokeLinecap="round"
              className="animate-draw"
            />
          </svg>
          <style>
            {`
              .animate-draw {
                stroke-dasharray: 100;
                stroke-dashoffset: 100;
                animation: draw 1s linear forwards;
              }

              @keyframes draw {
                to {
                  stroke-dashoffset: 0;
                }
              }
            `}
          </style>
        </div>



        <div style={{ zIndex: '1' }} className="flex w-[70vw] lg:w-[80vw] mb-[30vh] lg:mb-[20vh] md:ml-[5vw] lg:ml-[3vw] flex-col md:flex-row  gap-8">
          {/* Initial Introductory Banner */}
          <div style={{ zIndex: '1' }} className="  text-center w-[80vw] lg:w-[90vw] mb-0 md:mb-16 mt-4 lg:mt-12 xl:mt-auto md:pb-16">
          <h1 class="text-7xl md:text-7xl font-medium leading-tighter tracking-tighter mb-4"><span class="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">The Future of Interviews is Here.</span></h1>
  
  <p class="text-3xl text-gray-600 dark:text-gray-400 mb-8">Revolutionize Your Interview Preparation with AVA - Your AI Interview Assistant</p>
  
</div>



          
        </div>

        {/* Key Features */}
        <div className="w-[82.1vw] lg:w-[82.1vw] lg:ml-[2.7vw] mt-8">
        <h1 className="text-5xl  font-medium leading-tighter tracking-tighter mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                Key Features
              </span>
            </h1>
        <div className="flex flex-wrap -m-4 mb-4">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
        </div>


        {/* Frequently Asked Questions */}
        <div className="lg:p-8 dark:bg-neutral-800 mb-8">
          <h1 className="text-5xl font-medium leading-tighter tracking-tighter mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
              FAQs
            </span>
          </h1>

          <CustomAccordion
            title="What is AVA?"
            content="AVA is your AI Interview Assistant designed to revolutionize interview preparation. It leverages advanced AI technologies to help users practice and prepare for interviews efficiently."
          />
          <CustomAccordion
            title="How does AVA work?"
            content="AVA provides personalized interview questions and feedback based on your input and the role you're applying for. It simulates real interview scenarios to enhance your preparation."
          />
          <CustomAccordion
            title="Is AVA suitable for all job types?"
            content="Yes, AVA is designed to cater to various job roles and industries. Whether you're applying for tech, finance, or creative roles, AVA tailors its questions to suit your needs."
          />
          <CustomAccordion
            title="How do I get started with AVA?"
            content="To get started, simply create an account on our website, and you can begin your interview preparation by selecting a role and practicing with AVA."
          />
          <CustomAccordion
            title="What support options are available?"
            content="We offer a range of support options including a comprehensive help center, email support, and community forums where users can share tips and experiences."
          />
        </div>


       

        <Footer></Footer>
      

       
       
      </div>
     
    </div>
  );
}
