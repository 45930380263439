import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCTopic } from "../../contexts/Ctopiccontext";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Typewriter } from "react-simple-typewriter";
import { useCUser } from "../../contexts/Cusercontext";
import sound from "../../assets/audio/record-start.wav";
import { useNavigate } from "react-router-dom";

function Test() {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const { username, menu,userId } = useCUser();
  const [conversation, setConversation] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);
  const [editableTranscript, setEditableTranscript] = useState("");
  const {
    transcript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [error, setError] = useState(null);
  const [grades, setGrades] = useState(null);

  const [isEnd, setisEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [isEditable, setIsEditable] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const {
    topic,
    subtopic,
    setSubT,
    role,
    addAiChat,
    addUserChat,
    question,
    company,
    subtype,
  } = useCTopic();

  // Function to set the topic when a button is clicked
  const handleAssesmentClick = () => {
    navigate("/test2");
  };

  const startInterview = async () => {
    try {
      // Fetch the initial question
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/start_interview`
      );
      const initialQuestion = {
        sender: "Assistant",
        message: response.data.initial_question,
        username: { username },
      };
      console.log(initialQuestion);

      try {
        // Request to generate and play audio for the initial question
        const response2 = await axios.post(
          "http://localhost:5000/api/generate_play_audio",
          {
            initial_question: response.data.initial_question,
          }
        );

        // Wait for 2 seconds before setting the conversation and playing the audio
        setTimeout(() => {
          // Assuming the response contains the path to the generated audio file
          //const audioUrl = "C:/Users/sivan/OneDrive/Documents/GitHub/Interview_Simulator/frontend/src/assets/audio/speech1.mp3"
          // Dynamically import and play the audio file
          //new Audio(audioUrl).play();

          //const audio = new Audio(audioUrl);
          //audio.play();

          // Set the conversation state with the initial question
          setConversation([initialQuestion]);
        }, 2000);
      } catch (error) {
        console.error("Error generating and playing audio:", error);
      }
    } catch (error) {
      console.error("Error starting the interview:", error);
      setError("There was an error starting the interview. Please try again.");
    }
  };

  const handleStartInterview = () => {
    startInterview();
  };

  const playAudioInHiddenWindow = () => {
    const user_audio_folder = `${userId}/`;  // Dynamically insert userId into folder path
    const audio_file_name = 'speech1.mp3';  // Define the audio file name
    const audio_file_path = user_audio_folder + audio_file_name;  // Create the full file path
    const audioUrl = `https://ava-speech.s3.amazonaws.com/${audio_file_path}`;  // Generate the final S3 URL

    // Create an audio element to play the audio
    const audio = new Audio(audioUrl);
    audio.play().catch((error) => {
      console.error("Failed to play audio:", error);
    });

     // Add event listener to detect when the audio has ended
     audio.addEventListener('ended', () => {
      console.log("Audio has finished playing.");
      // You can perform any additional actions here, e.g. update state
      setIsAnswering(false);
    });
   
  };

  const playAudioInHiddenWindow2 = () => {
    const user_audio_folder = `${userId}/`;  // Dynamically insert userId into folder path
    const audio_file_name = 'speech2.mp3';  // Define the audio file name
    const audio_file_path = user_audio_folder + audio_file_name;  // Create the full file path
    const audioUrl = `https://ava-speech.s3.amazonaws.com/${audio_file_path}`;  // Generate the final S3 URL

    // Create an audio element to play the audio
    const audio = new Audio(audioUrl);
    audio.play().catch((error) => {
      console.error("Failed to play audio:", error);
    });

     // Add event listener to detect when the audio has ended
     audio.addEventListener('ended', () => {
      console.log("Audio has finished playing.");
      // You can perform any additional actions here, e.g. update state
      setIsAnswering(false);
    });
   
  };
  

  const testPrompt = async () => {
    try {
      const requestBody = {
        topic_name: topic,
        subtopic_name: subtopic,
        subtype_name: subtype,
        question: question,
      };

      setIsLoading(true);
      setIsAnswering(true);
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/test_interview`,
        requestBody
      );
      const initialQuestion = response.data.initial_question;
  
     

      console.log('generating audio in backend');
  
      // Request to generate and play audio for the initial question
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate_play_audio_3`,
        {
          initial_question: initialQuestion,
          user_id: userId  // Include userId in the request
        }
      );

       // Wait for 2 seconds before setting the conversation
       setTimeout(() => {
        setIsLoading(false);
        setConversation([{ sender: "Assistant", message: initialQuestion }]);
        addAiChat(initialQuestion);
        playAudioInHiddenWindow()
      }, 10000);

      console.log('setting audio url');
  
      // Open a new hidden window and play the audio
     
      
      
  
      
  
      
  
    } catch (error) {
      console.error("Error starting the interview:", error);
      setError("There was an error starting the interview. Please try again.");
    }
  };

  console.log(isAnswering);

 

  const handletestPrompt = () => {
    testPrompt();
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSend = async () => {
    // Start typing animation
    setIsTyping(true);
    setIsAnswering(true);
    if (editableTranscript.trim() === "") return;

    const userMessage = { sender: "User", message: editableTranscript };
    setConversation((prevConversation) => [...prevConversation, userMessage]);
    addUserChat(userMessage);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/continue_interview`,
        {
          editableTranscript,
        }
      );

      const aiMessage = {
        sender: "Assistant",
        message: response.data.response,
      };
      console.log(response.data.response);
      addAiChat(response.data.response);

      if (aiMessage.message.includes(" let's wrap up this session.")) {
        // Perform your action here, e.g., display a notification or end the session

        setisEnd(true);
      }

      setInput("");
      setError(null);

      // Request to generate and play audio for the AI response
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/generate_play_audio_4`,
        {
          text: response.data.response,
          user_id: userId 
        }
      );

      setTimeout(() => {
        setIsTyping(false);
        // Set the conversation state with the initial question
        setConversation((prevConversation) => [...prevConversation, aiMessage]);
        playAudioInHiddenWindow2()
      }, 10000);



     


    } catch (error) {
      console.error("Error during the API call:", error);
      setError("There was an error processing your request. Please try again.");
    }
  };

  function play() {
    new Audio(sound).play();
  }

  useEffect(() => {
    console.log(isEditable);
  }, [isEditable]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
      setEditableTranscript("");
    }
  };

  const startListening = () => {
    play();
    resetTranscript(); // Clear any existing transcript
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const stopListening = () => {
    play();
    SpeechRecognition.stopListening();
    setEditableTranscript(transcript); // Update editableTranscript with the final transcript
  };

  if (!browserSupportsSpeechRecognition) {
    return <p>Speech recognition not supported!</p>;
  }

  const handlingChange = (event) => {
    setEditableTranscript(event.target.value);
  };

  return (
    <div className="container ml-auto mr-auto p-0">
      {/* Layout for small and medium screens */}
      <div className="block lg:hidden p-4 flex flex-col gap-4 rounded-md dark:bg-gray-800 dark:text-white">
        <div
          style={{ width: "100%", height: "50%" }}
          className="flex flex-row gap-2 items-center rounded-md shadow-md bg-gray-200 dark:bg-gray-700 dark:text-gray-100 p-2"
        >
          <h1 className="text-xl font-medium text-gray-800 dark:text-gray-100">
            Interview Simulator
          </h1>

          <div className="flex flex-row gap-2 ml-auto">
            <div
              className="bg-gray-300 text-gray-700 dark:bg-gray-600 dark:text-gray-100 rounded-md w-30 h-10 p-2 "
              onClick={handletestPrompt}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                />
              </svg>
            </div>
            {isEnd && !isAnswering && (
              <div
                className="bg-gray-300 text-gray-700 dark:bg-gray-600 dark:text-gray-100 rounded-md w-30 h-10 p-2"
                onClick={() => handleAssesmentClick()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
              </div>
            )}
            
          </div>

          {error && (
            <div className="text-red-600 dark:text-red-400">{error}</div>
          )}
          {grades && (
            <div className="text-green-600 dark:text-green-400 mt-4">
              {grades}
            </div>
          )}
        </div>
        <div
          className="justify-end mb-4 flex flex-col p-2 rounded-md shadow-md bg-stone-100 dark:bg-gray-900 justify-end"
          style={{ width: "100%", height: "85vh", maxHeight: "85vh" }}
        >
          <div
            style={{ maxHeight: "90%", height: "90%" }}
            className="flex flex-col flex-1 space-y-2 overflow-y-auto mb-4"
          >
              {isLoading && (     
              <div aria-label="Initiating Interview Session..." role="status" class="flex items-center ml-[15vw] mt-[35vh] space-x-2">
              <svg class="h-10 w-10 animate-spin stroke-gray-500" viewBox="0 0 256 256">
                  <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
                  <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
                  <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
              </svg>
              <span class="text-xl font-light text-gray-600 animate-pulse">Initiating Interview Simulation...</span>
              </div>
            )}

            {conversation.map((msg, index) => (
              <div key={index} className="flex flex-col pr-2">
                <span
                  className={`p-2 text-sm rounded-lg ${
                    msg.sender === "User"
                      ? "self-end ml-4 bg-gray-200 dark:bg-gray-700 dark:text-gray-100"
                      : "self-start mr-4 bg-gray-300 dark:bg-gray-600 dark:text-gray-100"
                  }`}
                >
                  {msg.sender !== "User" ? (
                    <Typewriter
                      words={[msg.message]}
                      loop={1}
                      cursor
                      cursorStyle=""
                      typeSpeed={60}
                      deleteSpeed={50}
                      delaySpeed={6000}
                    />
                  ) : (
                    msg.message
                  )}
                </span>
              </div>
            ))}

              {/* Typing indicator for interviewer, shown when expected before AI response */}
              {isTyping && (
              <div className="flex flex-row gap-1 p-2 ml:w-[2vw] justify-left items-left dark:invert">
               
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce"></div>
              </div>
            )} 


          </div>
          <div className="flex space-x-2 mt-auto bg-stone-100 dark:bg-gray-900">
            {listening ? (
              <button
                className="px-4 py-2 text-sm bg-stone-200 dark:bg-gray-700 text-black dark:text-gray-100 hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600 rounded-md"
                onClick={stopListening}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
              </button>
            ) : (
              <button
                className="px-4 py-2 text-sm bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-100 hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600 rounded-md"
                onClick={startListening}
                disabled={isAnswering || isEnd}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                  />
                </svg>
              </button>
            )}
            <button
              onClick={handleSend}
              disabled={isAnswering || isEnd}
              className="px-4 py-2 text-sm bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-100 hover:bg-black hover:text-stone-200 dark:hover:bg-gray-600 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                />
              </svg>
            </button>
            {listening ? (
              <input
                className="main-content text-stone-600 dark:text-gray-300 h-10 pl-4 rounded-md w-full focus:outline-none dark:bg-gray-800"
                type="text"
                value={transcript} // Display the current transcript while listening
                placeholder="Listening..."
                disabled
              />
            ) : (
              <input
                className="main-content h-10 pl-4 rounded-md w-full focus:outline-none dark:bg-gray-800 text-black dark:text-gray-300"
                type="text"
                value={editableTranscript} // Use editableTranscript for input value when not listening
                onChange={handlingChange}
                onKeyDown={handleKeyDown} // Handle changes to editableTranscript
                placeholder={
                  isAnswering
                    ? "Wait for Interviewer to finish"
                    : isEnd
                    ? "Simulation is Over"
                    : "Transcription will appear here..."
                }
                disabled={isAnswering || isEnd}
              />
            )}
          </div>
        </div>
      </div>

      {/* Layout for large and extra large screens */}
      <div className="hidden lg:flex mt-[4vh] flex-row gap-4  mx-auto max-w-screen-2xl h-full dark:bg-neutral-800 dark:text-white">
        <div
          style={{ width: "30%", height: "100%" }}
          className="flex flex-col gap-2 rounded-md bg-white  dark:bg-neutral-800"
        >
          <div
            style={{ width: "100%", height: "50%" }}
            className="flex flex-col gap-2 rounded-md shadow-md bg-stone-200 dark:bg-neutral-700 p-4"
          >
            <h1 className="text-xl font-medium text-stone-800 dark:text-gray-100">
              Interview Simulator
            </h1>
            <h1 className="text-lg font-light text-stone-700 dark:text-gray-300">
              Topic: {topic}{" "}
            </h1>
            <h1 className="text-lg font-light text-stone-700 dark:text-gray-300">
              Subtopic: {subtopic}
            </h1>
            <h1 className="text-lg font-light text-stone-700 dark:text-gray-300">
              Subtype: {subtype}
            </h1>
            <h1 className="text-lg font-light text-stone-700 dark:text-gray-300">
              Company: {company}
            </h1>

            <div className="flex flex-row gap-2">
              <div
                className="bg-black text-stone-200 rounded-md w-30 h-10 p-2 hover:text-black hover:bg-stone-300 hover:cursor-pointer"
                onClick={handletestPrompt}
              >
                Start Interview
              </div>
              

              {isEnd && !isAnswering && (
                <div
                  className="bg-black text-stone-200 rounded-md w-30 h-10 p-2 hover:text-black hover:bg-stone-300 hover:cursor-pointer"
                  onClick={() => handleAssesmentClick()}
                >
                  View Assessment
                </div>
              )}

            </div>

            {error && <div className="text-red-600">{error}</div>}
            {grades && <div className="text-green-600 mt-4">{grades}</div>}
          </div>

          <div
            style={{ width: "100%", height: "50%" }}
            className="rounded-md shadow-md bg-stone-50 dark:bg-neutral-700 p-3"
          >
            <h1 className="text-xl font-medium text-stone-800 dark:text-gray-100">
              Video Analysing is coming soon
            </h1>
            <h2 className="text-lg font-light text-stone-700 dark:text-gray-300 mt-4">
              We're working hard to bring you a brand new experience. Stay tuned
              for updates as we get closer to launch. We can't wait to share
              what we've been working on!
            </h2>
          </div>
        </div>
        <div
          style={{ width: "68%", height: "83vh", maxHeight: "83vh" }}
          className="flex flex-col p-2 rounded-md shadow-md bg-stone-100 dark:bg-neutral-900"
        >
          <div
            style={{ maxHeight: "90%" }}
            className="flex flex-col flex-1 space-y-2 overflow-y-auto rounded-md "
          >
            {isLoading && (     
              <div aria-label="Initiating Interview Session..." role="status" class="flex items-center ml-[15vw] mt-[35vh] space-x-2">
              <svg class="h-10 w-10 animate-spin stroke-gray-500" viewBox="0 0 256 256">
                  <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
                  <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
                  <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="24"></line>
                  <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
                  <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" stroke-linecap="round" stroke-linejoin="round" stroke-width="24">
                  </line>
              </svg>
              <span class="text-xl font-light text-gray-600 animate-pulse">Initiating Interview Simulation...</span>
              </div>
            )}
      
            {conversation.map((msg, index) => (
              <div key={index} className="flex flex-col pr-2">
                <span
                  className={`p-2 text-sm rounded-lg ${
                    msg.sender === "User"
                      ? "self-end ml-64 bg-gray-200 dark:bg-gray-700 dark:text-gray-100"
                      : "self-start mr-64 bg-gray-300 dark:bg-gray-600 dark:text-gray-100"
                  }`}
                >
                  {msg.sender !== "User" ? (
                    <Typewriter
                      words={[msg.message]}
                      loop={1}
                      cursor
                      cursorStyle=""
                      typeSpeed={60}
                      deleteSpeed={50}
                      delaySpeed={6000}
                    />
                  ) : (
                    msg.message
                  )}
                </span>
              </div>
            ))}

            {/* Typing indicator for interviewer, shown when expected before AI response */}
            {isTyping && (
              <div className="flex flex-row gap-1 p-2 ml:w-[2vw] justify-left items-left dark:invert">
               
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce"></div>
              </div>
            )} 

          </div>
          <div className="flex space-x-2 mt-auto bg-stone-100 dark:bg-neutral-900">
            {listening ? (
              <input
                className="main-content dark:bg-gray-700 text-stone-600 h-10 pl-4 rounded-md w-full focus:outline-none"
                type="text"
                value={transcript} // Display the current transcript while listening
                placeholder="Listening..."
                disabled
              />
            ) : (
              <input
                className="main-content dark:bg-neutral-700 h-10 pl-4 rounded-md w-full focus:outline-none"
                type="text"
                value={editableTranscript} // Use editableTranscript for input value when not listening
                onChange={handlingChange}
                onKeyDown={handleKeyDown} // Handle changes to editableTranscript
                placeholder={
                  isAnswering
                    ? "Wait for Interviewer to finish"
                    : isEnd
                    ? "Simulation is Over"
                    : "Transcription will appear here..."
                }
                disabled={isAnswering || isEnd}
              />
            )}
            {listening ? (
              <button
                className="px-4 py-2 text-sm dark:bg-neutral-700 dark:text-gray-400 bg-stone-200 text-black hover:bg-black hover:text-stone-200 rounded-md"
                onClick={stopListening}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
              </button>
            ) : (
              <button
                className="px-4 py-2 text-sm dark:bg-neutral-700 dark:text-gray-400 bg-stone-200 text-black hover:bg-black hover:text-stone-200 rounded-md"
                onClick={startListening}
                disabled={isAnswering || isEnd}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                  />
                </svg>
              </button>
            )}
            <button
              onClick={handleSend}
              disabled={isAnswering || isEnd}
              className="px-4 py-2 text-sm dark:bg-neutral-700 dark:text-gray-400 bg-stone-200 text-black hover:bg-black hover:text-stone-200 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
