import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import img from "../../assets/images/coming-soon.png";
import { useCUser } from '../../contexts/Cusercontext'; 

function AdminFeedback() {
  const { toggleDarkMode, isDarkMode } = useCUser();
  // Funtion to handle the fetching of subjects
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    notifications: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form data submitted:", formData);
  };

  return (
    <div className="container p-4 " style={{marginLeft: '3.5vw'}}>
        {/* Unified template with responsive utilities */}
        <div className={`w-[89vw] md:w-[92vw] lg:h-[84vh] lg:w-[90vw] xl:w-[90vw] xl:h-[70vh] rounded-md bg-purple-200 sm:bg-green-200 md:bg-yellow-200 lg:bg-white lg:dark:bg-neutral-800 xl:bg-pink-100 xl:dark:bg-neutral-800`}>
        <div className="ml-[5vw] mr-[10vw] flex flex-row gap-2">
          <div className="md:w-9/12 flex flex-col">
            <h1 className="text-5xl font-bold ml-8 mt-4 leading-tighter tracking-tighter mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black dark:text-white">
                This Feature is Coming Soon
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-3xl font-light ml-8 text-gray-600 dark:text-gray-400 mb-4">
                We’re working hard to bring you a brand new experience. Stay
                tuned for updates as we get closer to launch.
              </p>
              <p className="text-2xl font-medium ml-8 text-gray-600 dark:text-gray-400 mb-8">
                We cant wait to share what we’ve been working on!
              </p>
            </div>
          </div>
          <div className="md:w-5/12 md:mt-1/2 md:ml-12 mt-10">
            <img
              src={img}
              alt="Chat Background"
              className="w-full h-auto object-cover rounded-md"
            />
          </div>
        </div>

       
       
          
        
          
         
        </div>
      </div>
  );
}

export default AdminFeedback;
