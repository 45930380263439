import React, { useState } from 'react'; // For icons

const CustomAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full mb-4">
      {/* Accordion Header */}
      <div
        className={`flex justify-between items-center p-4 rounded-md cursor-pointer bg-gray-100 dark:bg-neutral-600 hover:bg-gray-200 dark:hover:bg-neutral-600 transition-colors duration-300`}
        onClick={toggleAccordion}
      >
        <h3 className="text-xl font-light text-gray-900 dark:text-gray-100">
          {title}
        </h3>
        <span className="text-gray-900 dark:text-gray-100">
          {isOpen ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
}
        </span>
      </div>

      {/* Accordion Content */}
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className="p-4 bg-white dark:bg-neutral-800 text-gray-900 dark:text-neutral-100 border-t border-gray-200 dark:border-neutral-600">
          {content}
        </div>
      </div>
    </div>
  );
};

export default CustomAccordion;
