import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCTopic } from "../../contexts/Ctopiccontext";
import img from "../../assets/images/coming-soon.png";

function Test2() {
  const { topic, subtopic, setSubT, role, aichat, userchat, company } =
    useCTopic();

  // Funtion to handle the fetching of subjects
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    notifications: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form data submitted:", formData);
  };

  const combineChats = (aichat, userchat) => {
    const combined = [];
    const maxLength = Math.max(aichat.length, userchat.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < aichat.length) {
        combined.push({ sender: "AI", messages: aichat[i] });
      }
      if (i < userchat.length) {
        combined.push({ sender: "User", messages: userchat[i] });
      }
    }

    return combined;
  };

  const combinedChats = combineChats(aichat, userchat);

  console.log(combinedChats);

  const getRubricGrades = async () => {
    try {
      console.log(topic);
      console.log(subtopic);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/rubric_grades`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ transcript: combinedChats }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch subtopics");
      }
      const data = await response.json();
      console.log(data.reply);
    } catch (error) {
      console.error("Error fetching subtopics:", error);
      // Handle error, show user feedback, etc.
    }
  };

  useEffect(() => {
    getRubricGrades(); // Call getRoles when the component mounts
  }, []); //

  return (
    <div
      style={{ width: "90%", height: "80vh%" }}
      className="flex flex-col md:flex- mt-5 gap-2 lg:pl-4 lg:pr-4 lg:pb-2 lg:pt-0  mx-auto max-w-screen-xl h-auto"
    >
      <div className="flex flex-row gap-5">
        <h1 className="text-lg font-semibold dark:text-white">
          Simulation Assessment
        </h1>
        <h1 className="text-lg font-light dark:text-gray-200">
          Topic: {topic}
        </h1>
        <h1 className="text-lg font-light dark:text-gray-200">
          Subtopic: {subtopic}
        </h1>
        <h1 className="text-lg font-light dark:text-gray-200"> Role: {role}</h1>
        <h1 className="text-lg font-light dark:text-gray-200">
          Company: {company}
        </h1>
        <h1 className="text-lg font-semibold ml-auto dark:text-white">
          Overall Grade: 5/5
        </h1>
      </div>
      <div
        style={{ width: "100%", height: "75vh" }}
        className="gap-6 flex flex-row"
      >
        <div
          style={{ width: "60%", height: "75vh" }}
          className="flex flex-col gap-5"
        >
          <div
            style={{ width: "100%", height: "45vh" }}
            className="bg-gray-200 dark:bg-gray-900 rounded-md p-3 "
          >
            <h1 className="text-md dark:text-white font-semibold mb-2">
              Interview Transcript
            </h1>

            <div
              style={{ maxHeight: "90%" }}
              className="flex flex-col flex-1 space-y-2 overflow-y-auto"
            >
              {combinedChats.map((msg, index) => (
                <div key={index} className="flex flex-col pr-2">
                  {msg.messages.map((messageObj, msgIndex) => (
                    <div key={msgIndex} className="flex">
                      <span
                        className={`p-2 text-sm rounded-lg ${
                          msg.sender === "User"
                            ? "bg-stone-200 text-stone-600"
                            : "bg-stone-500 text-stone-200"
                        }`}
                      >
                        {msg.sender === "AI"
                          ? `Clara | ${messageObj.message || messageObj}`
                          : `User  | ${messageObj.message || messageObj}`}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div
            style={{ width: "100%", height: "35%" }}
            className="bg-gray-200 dark:bg-gray-900 rounded-md p-3"
          >
            <h1 className="text-md dark:text-white font-semibold">My Notes</h1>
            <textarea className="w-full dark:text-gray-200 rounded-md mt-2 h-24 max-h-24 bg-gray-100 dark:bg-gray-700 p-2 focus:outline-none"></textarea>
          </div>
        </div>
        <div
          style={{ width: "40%", height: "100%" }}
          className="flex flex-col gap-2"
        >
          <div
            style={{ width: "100%", height: "85%" }}
            className="bg-gray-100 dark:bg-gray-900 rounded-md p-3"
          >
            <h1 className="text-md dark:text-white font-semibold">
              Marking Rubric
            </h1>
            <table className="min-w-full divide-y divide-gray-200 rounded-md mt-2">
              <thead className="bg-stone-200">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Criteria
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Grade
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Achieved
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr className="hover:bg-stone-100">
                  <td className="px-6 py-4 whitespace-nowrap">1</td>
                  <td className="px-6 py-4 whitespace-nowrap">Product</td>
                  <td className="px-6 py-4 whitespace-nowrap">User Research</td>
                </tr>
                <tr className="hover:bg-stone-100">
                  <td className="px-6 py-4 whitespace-nowrap">2</td>
                  <td className="px-6 py-4 whitespace-nowrap">Engineering</td>
                  <td className="px-6 py-4 whitespace-nowrap">Backend</td>
                </tr>
                <tr className="hover:bg-stone-100">
                  <td className="px-6 py-4 whitespace-nowrap">3</td>
                  <td className="px-6 py-4 whitespace-nowrap">Consulting</td>
                  <td className="px-6 py-4 whitespace-nowrap">Strategy</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{ width: "100%", height: "5%" }}
            className="flex flex-row gap-2 mb-3"
          >
            <button className="flex flex-row gap-2 p-2 w-36 h-10 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              Back
            </button>
            <button className="flex flex-row gap-2 p-2 w-36 h-10 bg-red-300 justify-center items-center text-black rounded-md mt-4 hover:bg-stone-300 hover:text-black">
              {" "}
              Delete
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
            <button className="flex flex-row gap-2 p-2 w-36 h-10 bg-black justify-center items-center text-white rounded-md mt-4 hover:bg-stone-300 hover:text-black">
              {" "}
              Download
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test2;
